import type { ModalProps } from 'antd';
import { message } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { getStaticResourceUrl } from 'egenie-common';
import { action, computed, observable } from 'mobx';
import { passwordReg } from '../../utils';
import type { InfoInterface, SecurityInterface, SecuritySettingsInterface } from './interface';

const ossUrl = `${getStaticResourceUrl('pc/ts/egenie-ts-vogue/images')}`;

const phoneReg = /\d{10,11}/;
let timer: any;
export default class SecurityCenter {
  constructor() {
    this.__init__();
  }

  @observable public isPassword: boolean; // 是否设置登陆密码

  @observable public isWX: boolean; // 是否绑定微信

  @observable public oauthInfo: InfoInterface;

  @observable public isMobileBind: boolean; // 是否绑定手机号

  @observable public isPayPassword: boolean; // 是否设置支付密码

  @observable public loading = false;

  @observable public passWordVisible = false;

  @observable public phoneVisible = false;

  @observable public wxVisible = false;

  @observable public getVerificationCode = false;

  @observable public number = 0;

  @observable public newPassWord: string;// 新密码

  @observable public confirmNewPassWord: string; // 确认新密码

  @observable public originalPassWord: string;// 原密码

  @observable public verificationCode: number;// 验证码

  @observable public originalMobile: number;// 原手机号

  @observable public newMobile: number;// 新手机号

  @observable public isNewMobile = false;

  @observable public accessCode: string;

  @action public __init__ = async() => {
    const req = await request<BaseData<SecurityInterface>>({
      method: 'GET',
      url: '/api/iac/user/security/status',
    });
    this.isPassword = req?.data?.initPassword;
    this.isMobileBind = req?.data?.mobileBind;
    this.isPayPassword = req?.data?.initPayPassword;
    const reqInfo = await request<BaseData<InfoInterface>>({
      method: 'GET',
      url: '/api/iac/user/oauth/info',
      params: { sysType: 'pc_shj' },
    });
    this.oauthInfo = reqInfo.data;
    this.isWX = Boolean(reqInfo?.data?.id || null);
    await this.getUserInfo();
  };

  @action
  public getUserInfo = async() => {
    const req = await request<{ mobile: string; }>({
      method: 'GET',
      url: '/api/dashboard/user',
    });
    this.originalMobile = Number(req.mobile);
  };

  public getSecuritySettings = (): SecuritySettingsInterface[] => {
    return [
      {
        title: '登录密码',
        icon: `${ossUrl}/lock.png`,
        buttonTxt: !this.isPassword ? '设置密码' : '修改密码',
        prompt: this.isPassword ? '已设置密码' : '未设置密码',
        onClick: action(() => {
          this.passWordVisible = true;
        }),
      },
      {
        title: '手机号',
        icon: `${ossUrl}/phone.png`,
        buttonTxt: '修改',
        prompt: '已绑定手机',
        onClick: action(() => {
          this.phoneVisible = true;
        }),
      },
      {
        title: '支付密码',
        icon: `${ossUrl}/security.png`,
        buttonTxt: this.isPayPassword ? '修改' : '设置',
        prompt: this.isPayPassword ? '已设置密码' : '未设置密码',
        onClick: () => {
          try {
            top.egenie.openTab('/egenie-ts-payment/accountCenter', 2684, '支付账户中心');
          } catch (e) {
            console.error(e);
          }
        },
      },
    ];
  };

  @computed
  public get getBinding(): SecuritySettingsInterface[] {
    return [
      {
        title: `绑定微信账号${this?.oauthInfo?.nickname ? `：${this?.oauthInfo?.nickname}` : ''}`,
        icon: `${ossUrl}/wx.png`,
        buttonTxt: this.isWX ? '解除绑定' : '去绑定',
        onClick: action(() => {
          try {
            if (this.isWX) {
              this.wxVisible = true;
            } else {
              const APP_ID = 'wxa4a94e5aaa4b41f2';
              const state = `${new Date().getTime()}pc_shj`;
              window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${APP_ID}&redirect_uri=https://shj.ejingling.cn/api/iam/login/bind/anon/user/pc_shj&response_type=code&scope=snsapi_login&state=${state}#wechat_redire`;
            }
          } catch (e) {
            console.error(e);
          }
        }),
      },
    ];
  }

  @action
  public onPassWordOk = async(): Promise<void> => {
    if (!this.originalPassWord || !this.newPassWord || !this.confirmNewPassWord) {
      message.warning('请填写完所有信息');
      return;
    }
    if (!passwordReg.test(`${this.newPassWord}`)) {
      message.warning('包含字母和数字且长度在8到16位的密码');
      return;
    }
    try {
      this.loading = true;
      if (this.newPassWord === this.confirmNewPassWord) {
        const req = await request<BaseData>({
          method: 'POST',
          url: '/api/iac/user/password/change',
          data: {
            newPassword: this.newPassWord,
            oldPassword: this.originalPassWord,
          },
        });
        message.success(req.info);
        this.onPassWordCancel();
      } else {
        message.error('两次新密码不相同');
      }
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  };

  @action
  public onPassWordCancel = () => {
    this.passWordVisible = false;
    this.newPassWord = undefined;
    this.confirmNewPassWord = undefined;
    this.originalPassWord = undefined;
  };

  @action
  public setValue = (key: string, value: string | boolean | number) => {
    this[key] = value;
  };

  @action
  public onPhoneOk = async(): Promise<void> => {
    if (!this.isNewMobile) {
      if (!this.originalMobile) {
        message.warning('未获取到原手机号');
        return;
      }
      if (!this.verificationCode) {
        message.warning('请获取验证码');
        return;
      }
      const req = await request<BaseData<string>>({
        method: 'POST',
        url: '/api/iac/user/mobile/validate',
        data: { smsCode: this.verificationCode },
      });
      this.accessCode = req.data;
      this.isNewMobile = true;
      clearInterval(timer);
      this.number = 0;
      this.getVerificationCode = false;
      this.verificationCode = undefined;
      return;
    }
    if (!this.newMobile) {
      message.warning('请填写完所有信息');
      return;
    }
    if (!this.verificationCode) {
      message.warning('请获取验证码');
      return;
    }
    try {
      this.loading = true;
      const req = await request<BaseData>({
        method: 'POST',
        url: '/api/iac/user/mobile/update',
        data: {
          accessCode: this.accessCode,
          newMobile: this.newMobile,
          smsCode: this.verificationCode,
        },
      });
      message.success(req.info);
      this.onPhoneCancel();
      await this.getUserInfo();
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  };

  @action
  public onPhoneCancel = () => {
    clearInterval(timer);
    this.newMobile = undefined;
    this.verificationCode = undefined;
    this.phoneVisible = false;
    this.originalMobile = undefined;
    this.accessCode = undefined;
    this.getVerificationCode = false;
    this.isNewMobile = false;
    this.number = 0;
  };

  @action
  public verificationCodeClick = () => {
    if (!this.newMobile && this.isNewMobile) {
      message.warning('请输入新手机号');
      return;
    }
    if (!this.originalMobile && !this.isNewMobile) {
      message.warning('无绑定手机号无法获取验证码');
      return;
    }
    if (!phoneReg.test(`${this.newMobile}`) && this.isNewMobile) {
      message.error('请输入正确手机号');
      return;
    }
    request({
      method: 'POST',
      url: '/api/iac/validCode/anon/send',
      data: {
        module: 100015,
        mobile: this.isNewMobile ? this.newMobile : this.originalMobile,
      },
    });
    this.getVerificationCode = true;
    timer = setInterval(() => {
      this.number += 1;
      if (this.number >= 60) {
        clearInterval(timer);
        this.getVerificationCode = false;
        this.number = 0;
      }
    }, 1000);
  };

  @action
  public onWXOk = async() => {
    try {
      this.loading = true;
      const req = await request<BaseData>({
        method: 'POST',
        url: '/api/iac/user/oauth/clear/binding',
        data: { id: this?.oauthInfo?.id },
      });
      message.success(req.info);
      this.oauthInfo = undefined;
      this.isWX = false;
      this.onWXCancel();
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  };

  @action
  public onWXCancel = () => {
    this.wxVisible = false;
  };

  public get getModalPassWordParams(): ModalProps {
    return {
      open: this.passWordVisible,
      title: this.isPassword ? '修改登陆密码' : '设置登陆密码',
      width: 322,
      okText: '保存',
      centered: true,
      destroyOnClose: true,
      onOk: this.onPassWordOk,
      onCancel: this.onPassWordCancel,
      confirmLoading: this.loading,
    };
  }

  public get getModalPhoneVisibleParams(): ModalProps {
    return {
      open: this.phoneVisible,
      title: '修改手机号',
      okText: !this.isNewMobile ? '下一步' : '确定',
      width: 322,
      centered: true,
      destroyOnClose: true,
      onOk: this.onPhoneOk,
      onCancel: this.onPhoneCancel,
      confirmLoading: this.loading,
    };
  }

  public get getModalWXVisibleParams(): ModalProps {
    return {
      open: this.wxVisible,
      title: '微信解除绑定',
      width: 322,
      centered: true,
      destroyOnClose: true,
      onOk: this.onWXOk,
      onCancel: this.onWXCancel,
      confirmLoading: this.loading,
    };
  }
}
